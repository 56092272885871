export class DOM {
    static queryAll(el, selector) {
        if(typeof el === 'string') {
            selector = el;
            el = document;
        }
        const elements = el.querySelectorAll(selector);
        return Array.prototype.slice.call(elements);
    }

    static ready(callback) {
        if (document.readyState !== 'loading') {
            callback();
        } else {
            document.addEventListener('DOMContentLoaded', callback);
        }
    }

    static html(template) {
        const tmp = document.implementation.createHTMLDocument('');
        tmp.body.innerHTML = template;
        return tmp.body.children;
    }
}
