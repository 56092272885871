/**
 * Run a function only once after 'wait' milliseconds. How to use: see unit tests
 * @param {Function} func Function to be used
 * @param {number} wait Time in ms to wait before running the function
 * @param immediate
 * @returns {Function}
 */
export function debounce(func, wait, immediate) {
    let timeout;
    return function() {
        const context = this, args = arguments; //eslint-disable-line no-invalid-this
        const callNow = immediate && !timeout;
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            timeout = null;
            !immediate && func.apply(context, args);
        }, wait);

        callNow && func.apply(context, args);
    };
}

export function waitUntil(check, onComplete, delay, timeout) {
    //if the check returns true, execute onComplete immediately
    if (check()) {
        onComplete();
        return;
    }
    if (!delay) {
        delay = 100;//eslint-disable-line no-magic-numbers
    }
    let timeoutPointer;
    const intervalPointer=setInterval(function () {
        if (!check()) {
            return;
        } // if check didn't return true, means we need another check in the next interval
        // if the check returned true, means we're done here. clear the interval and the timeout and execute onComplete
        clearInterval(intervalPointer);
        if (timeoutPointer) {
            clearTimeout(timeoutPointer);
        }
        onComplete();
    }, delay);
    // if after timeout milliseconds function doesn't return true, abort
    if (timeout){
        timeoutPointer = setTimeout(function () {
            clearInterval(intervalPointer);
        }, timeout);
    }
}
