import {ViewPort} from './matchMedia';

window.getChannelId = function () {
    const viewport = ViewPort.getCurrent();
    return viewport === ViewPort.lg || viewport === ViewPort.xl ? -1 : -6; //eslint-disable-line no-magic-numbers
};

export function hasClass(el, className) {
    return el.classList ?  el.classList.contains(className) : !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
}

export function addClass(el, className) {
    if (el.classList){
        el.classList.add(className);
    } else if (!hasClass(el, className)){
        el.className += ' ' + className;
    }
}

export function removeClass(el, className) {
    if (el.classList){
        el.classList.remove(className);
    } else if (hasClass(el, className)) {
        var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
        el.className=el.className.replace(reg, ' ');
    }
}

export function isVisible (ele) {
    return  ele.clientWidth !== 0 &&
      ele.clientHeight !== 0 &&
      ele.style.opacity !== 0 &&
      ele.style.visibility !== 'hidden';
}

// Fix for banner, when AEM styling add less margin under button
function buttonPadding(){
    const buttonSelector = document.querySelector('.main-container .banner .btn--tertiary');
    const priceMessage = document.querySelector('.main-container .banner .banner__content__price');
    if (priceMessage){
        addClass(buttonSelector, 'btnReducedPadding');
    }
}

export function includes(array1, array2) {
    return array1.some(r => array2.indexOf(r) >= 0);
}

export function triggerEvent( elem, event ) {
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent(event, true, false, window, 0);
        window.dispatchEvent(evt);
    } else {
        var clickEvent = new Event( event );
        elem.dispatchEvent( clickEvent );
    }
}

buttonPadding();